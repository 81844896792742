import React from 'react'
import { graphql, navigate } from 'gatsby'
import { Layout, Container } from '../layouts'
import { Helmet } from 'react-helmet'
import Hero from '../components/Global/Hero'
import CTA from '../components/Global/CTA'
import ArticleList from '../components/Blog/ArticleList'
import Pagination from '../components/Global/Pagination'
import SearchForm from '../components/Blog/SearchForm'
import Filters from '../components/Blog/Filters'
import BlogCarousel from '../components/Blog/Carousel'
import { colors } from '../../lib/constants'
import { useFlexSearch } from 'react-use-flexsearch'
import * as queryString from 'query-string'

const paginator = (data, page, perPageItems) => {
  return {
    page,
    items: data.slice((page - 1) * perPageItems, page * perPageItems),
    totalPages: Math.ceil(data.length / perPageItems),
  }
}

const ThoughtsPage = ({
  data: {
    localSearchThoughts: { index, store },
    pageData: { frontmatter },
    allArticles: { edges: articles },
  },
  location,
}) => {
  const { cta, featured_articles } = frontmatter
  let query = queryString.parse(location.search)
  let results = useFlexSearch(query.q, index, store)
  const allArticles = articles.map((it) => {
    const { id, html } = it.node
    const { slug } = it.node.fields
    const { title, tags, description, author, hero_image, date, category } =
      it.node.frontmatter
    return {
      id,
      html,
      slug,
      description,
      title,
      tags,
      author,
      hero_image,
      date,
      category,
    }
  })
  if (!query.q) {
    results = allArticles
  }
  if (!query.page) query.page = 1
  if (query.category) {
    results = results.filter(
      (it) =>
        it.category === query.category ||
        it?.node?.frontmatter?.category === query.category
    )
  }
  const categories = articles
    .map((article) => article.node.frontmatter.category)
    .filter((value, index, self) => value && self.indexOf(value) === index)
  if (query.sort && query.sort === 'asc') {
    results.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    )
  } else {
    results.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    )
  }

  const searchResults = paginator(results, query.page, 12)

  const onSearchFiltersChange = (query) => {
    const queryParams = queryString.stringify(query)
    navigate(`/thoughts/?${queryParams}`)
  }

  return (
    <Layout>
      <Helmet>
        <title>Blog - TechEquipt</title>
        <meta
          name="description"
          content="TechEquipt Blog talking all items technical. Such as websites and web-based systems."
        />
      </Helmet>
      <Hero
        title={query.category || query.q ? '' : 'Thoughts'}
        small
        searchForm={
          <SearchForm
            large={query.category || query.q}
            query={query}
            onChange={onSearchFiltersChange}
          />
        }
        filters={
          <Filters
            categories={categories}
            query={query}
            onChange={onSearchFiltersChange}
          />
        }
      />
      {featured_articles &&
        featured_articles.length > 0 &&
        !query.category &&
        !query.q && (
          <Container padding="8rem 0 6rem">
            <BlogCarousel articles={featured_articles} />
          </Container>
        )}
      <Container>
        {results && results.length > 0 && (
          <ArticleList articles={searchResults.items} showNewsletterForm />
        )}
        <Pagination
          page={searchResults.page || 1}
          totalPages={searchResults.totalPages}
          query={query}
        />
      </Container>

      {cta.title && cta.image.publicURL && (
        <Container color={colors.white}>
          <CTA
            bgColor="#F7F6F6"
            heading={cta.title}
            content={cta.content}
            btnText={cta.button_text}
            btnLink={cta.button_link}
            image={cta.image.publicURL}
          />
        </Container>
      )}
    </Layout>
  )
}

export default ThoughtsPage

export const pageQuery = graphql`
  query ThoughtsPageTemplate {
    localSearchThoughts {
      index
      store
    }
    allArticles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            hero_image {
              publicURL
            }
            date
            tags
            description
            author
            category
          }
        }
      }
    }
    pageData: markdownRemark(
      frontmatter: { templateKey: { eq: "thoughts-page" } }
    ) {
      frontmatter {
        featured_articles {
          article {
            fields {
              slug
            }
            frontmatter {
              title
              description
              tags
              hero_image {
                publicURL
              }
              date
              author
            }
          }
        }
        cta {
          title
          content
          image {
            publicURL
          }
          button_text
          button_link
        }
      }
    }
  }
`
