import React from 'react'
import ArrowDown from '../../Global/Icon/ArrowDown'
import * as S from './Filters.css'

const Filters = ({ categories, query, onChange }) => {
  const onSort = e => {
    query.sort = e.target.value
    onChange(query)
  }
  const onCategoryChange = e => {
    query.category = e.target.value
    query.page = 1
    onChange(query)
  }

  return (
    <S.Filters>
      <S.InputGroup>
        <S.Select
          defaultValue={query.category || ''}
          onChange={onCategoryChange}
        >
          <option value="">All category</option>
          {categories.map(it => (
            <option value={it} key={it}>
              {it}
            </option>
          ))}
        </S.Select>
        <ArrowDown />
      </S.InputGroup>
      <S.InputGroup>
        <S.Select defaultValue={query.order || 'desc'} onChange={onSort}>
          <option value="desc">Lastest</option>
          <option value="asc">Oldest</option>
        </S.Select>
        <ArrowDown />
      </S.InputGroup>
    </S.Filters>
  )
}

export default Filters
