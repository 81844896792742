import React, { useRef } from 'react'
import SearchIcon from '../../Global/Icon/Search'
import * as S from './SearchForm.css'

const SearchForm = ({ large, query, onChange }) => {
  const searchInputRef = useRef()

  const onSearch = e => {
    e.preventDefault()
    query.q = searchInputRef.current.value
    onChange(query)
  }

  return (
    <S.SearchForm onSubmit={onSearch} large={large}>
      <S.InputGroup>
        <S.Input
          ref={searchInputRef}
          type="text"
          placeholder="Search for blog..."
          defaultValue={query.q || ''}
        />
        <SearchIcon />
      </S.InputGroup>
    </S.SearchForm>
  )
}

export default SearchForm
