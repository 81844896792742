import styled from 'styled-components'
import { colors, textSm, media } from '../../../../lib/constants'

export const Filters = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  ${media.desktop`
    width: 100%;
    flex: 0 0 100%;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  `}
`

export const InputGroup = styled.div`
  position: relative;
  width: 100%;
  max-width: 185px;
  display: inline-block;
  margin-left: 20px;

  ${media.desktop`
    max-width: calc(50% - 10px);
    margin-left: 0;
  `}

  svg {
    position: absolute;
    right: 10px;
    top: 16px;
  }
`

export const Select = styled.select`
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 3px;
  color: ${colors.white};
  ${textSm}
  padding: 8px 30px 8px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  width: 100%;
`
