import React from 'react'
import { navigate } from 'gatsby'
import * as queryString from 'query-string'
import * as S from './Pagination.css'

export const getPaginationData = (results, page, limit) => {
  return {
    page: page,
    totalPages: Math.ceil(results.length / limit),
  }
}

const Pagination = ({ totalPages, page, query }) => {
  const gotoPage = page => {
    query.page = page
    const urlParams = queryString.stringify(query)
    navigate(`/thoughts/?${urlParams}`)
  }

  return (
    <S.Pagination>
      {page > 1 && (
        <span
          className="p-item p-prev"
          role="link"
          onClick={() => gotoPage(page - 1)}
        >
          &lt;
        </span>
      )}
      {Array.from({ length: totalPages }, (_, i) =>
        parseInt(page) === i + 1 ? (
          <span key={`p-number${i + 1}`} className="p-item active">
            {i + 1}
          </span>
        ) : (
          <span
            role="link"
            className="p-item"
            key={`p-number${i + 1}`}
            onClick={() => gotoPage(i + 1)}
          >
            {i + 1}
          </span>
        )
      )}
      {page < totalPages && (
        <span
          className="p-item p-prev"
          role="link"
          onClick={() => gotoPage(page + 1)}
        >
          &gt;
        </span>
      )}
    </S.Pagination>
  )
}

export default Pagination
