import styled from 'styled-components'
import { colors, fonts } from '../../../../lib/constants'

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .p-item {
    margin: 0 14px;
    color: #999dae;
    font-family: ${fonts.headings};
    font-weight: 400;
    cursor: pointer;

    &.active {
      cursor: default;
    }

    &.active,
    &:hover,
    &:focus {
      color: ${colors.blue} !important;
    }

    &.p-prev,
    &.p-next {
      color: ${colors.blue} !important;
    }
  }

  a:before {
    display: none;
  }
`
