import React from 'react'

const ArrowDown = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99999 3.879L8.71249 0.166504L9.77299 1.227L4.99999 6L0.22699 1.227L1.28749 0.166504L4.99999 3.879Z"
      fill="white"
    />
  </svg>
)

export default ArrowDown
