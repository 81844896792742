import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import Article from '../../Global/Article'
import useWindowSize from '../../../hooks/useWindowSize'
import * as S from './Carousel.css'

const Featured = ({ articles }) => {
  const [key, setKey] = useState(0)
  const windowSize = useWindowSize()
  const [settings, setSettings] = useState({
    infiniteLoop: true,
    showArrows: false,
    showStatus: false,
    showThumbs: false,
    showIndicators: true,
    interval: 10000,
    transitionTime: 600,
    selectedItem: key,
    centerMode: true,
    onClickItem: (index) => {
      setKey(index)
    },
    onChange: (index) => {
      setKey(index)
    },
    renderIndicator: (onClickHandler, isSelected, index) => {
      if (isSelected) {
        return <S.Indicator active>{`0${index + 1} __`}</S.Indicator>
      }
      return (
        <S.Indicator
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role="button"
          tabIndex={0}
        >
          {`0${index + 1}`}
        </S.Indicator>
      )
    },
  })

  useEffect(() => {
    const isMobile = windowSize.width < 768

    setSettings({
      ...settings,
      selectedItem: key,
      centerSlidePercentage: isMobile ? 100 : 80.75,
      autoPlay: isMobile ? false : false,
    })
  }, [windowSize.width, key, settings])

  return (
    <S.Articles>
      <S.ArticlesCarousel>
        <Carousel {...settings}>
          {articles.map(({ article }, key) => (
            <S.CarouselItem key={`featured-${key}`}>
              <img
                src={article.frontmatter?.hero_image?.publicURL}
                alt={article.frontmatter.title}
              />
              <S.CarouselInfo></S.CarouselInfo>
            </S.CarouselItem>
          ))}
        </Carousel>
      </S.ArticlesCarousel>

      <S.ArticlesList>
        {articles.map(
          ({ article }, index) =>
            index === key && (
              <Article
                carousel
                slug={article.frontmatter.slug}
                title={article.frontmatter.title}
                description={article.frontmatter.description}
                tags={article.frontmatter.tags}
                author={article.frontmatter.author}
                date={article.frontmatter.date}
              />
            )
        )}
      </S.ArticlesList>

      <S.Indicators>
        {[0, 1, 2].map((it, index) => (
          <S.Indicator
            key={it}
            active={index === key}
            onClick={() => setKey(index)}
          >{`0${index + 1}${index === key ? '__' : ''}`}</S.Indicator>
        ))}
      </S.Indicators>
    </S.Articles>
  )
}

export default Featured
