import styled, { css } from 'styled-components'
import { colors, media, textMd } from '../../../../lib/constants'

export const Articles = styled.div`
  padding-bottom: 0.25rem;
  position: relative;

  &:after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    right: 100%;
    top: 0;
    background: red;
    height: 100%;
    z-index: 9;
    display: block;
    background: ${colors.white};
  }
`

export const ArticlesList = styled.div`
  margin: 1.25rem 0 0;
  max-width: 82.9%;

  > article {
    margin-bottom: 0;
  }

  ${media.tablet`
    margin: 1rem 0;
    max-width: 100%;
  `}
`

export const CarouselItem = styled.div`
  background-size: 100%;
  background-repeat: no-repeat;

  img {
    border-radius: 10px;
  }

  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
    `}
`

export const CarouselInfo = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: -45px;
  left: 0;
  color: ${colors.blue};

  ${media.desktop`
    bottom: -70px;
  `}

  ${media.tablet`
    display: none;
  `}
`

export const CarouselInfoTitle = styled.h3`
  ${textMd}
  font-weight: 700;
  margin-bottom: 5px;

  ${media.desktop`
    margin-bottom: 0;
  `}
`

export const ArticlesCarousel = styled.div`
  .carousel.carousel-slider {
    overflow: inherit !important;
  }

  .slider-wrapper {
    margin-left: -100px !important;
    margin-right: -1000px !important;
    overflow: inherit !important;
  }

  ${media.wider`
    .slider-wrapper {
      margin-left: -90px !important;
    }
  `}

  ${media.wide`
    .slider-wrapper {
      margin-left: -80px !important;
    }
  `}

  ${media.desktop`
    .slider-wrapper {
      margin-left: -75px !important;
    }
  `}

  ${media.tablet`
    .slider-wrapper {
      margin-left: 0 !important;
      margin-right: 0 !important;
      overflow: hidden !important;
    }
  `}

  .slide {
    padding: 30px 10px;
    transition: all 1.2s;
    position: relative;
    transform: scale(0.95);

    img {
      position: relative;
      top: 30px;
    }

    &.selected {
      transform: scale(1.05);
      ${CarouselInfo} {
        opacity: 0;
      }

      img {
        top: auto;
      }
    }

    img {
      margin-bottom: 0;
    }
  }

  .control-dots {
    position: relative !important;
    display: flex;
    justify-content: flex-end;
    margin-top: 0 !important;
    margin-bottom: 37px !important;
  }

  ${media.tablet`
    .carousel .slider-wrapper {
      width: calc(100% + 20px);
      left: -10px;
      position: relative;
    }
  
    .carousel.carousel-slider {
      overflow: visible;
    }
    
    .control-dots {
      display: none !important;
    }

    .slide {
      padding: 0 10px 0.5rem;
      transform: scale(1);
  
      &.selected {
        transform: scale(1);
      }

      img {
        top: auto;
      }
    }
  `}
`

export const Indicator = styled.div`
  margin-left: 30px;
  cursor: pointer;
  color: #999dae;

  ${props =>
    props.active &&
    css`
      color: ${colors.blue};
    `}
`

export const Indicators = styled.div`
  display: none;
  padding-top: 1.5rem;

  ${Indicator} {
    margin-left: 0;
    margin-right: 26px;
  }

  ${media.tablet`
    display: flex;
  `}
`
