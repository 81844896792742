import styled, { css } from 'styled-components'
import { colors, textSm, media } from '../../../../lib/constants'

export const SearchForm = styled.form`
  display: inline-block;
  margin-bottom: 0;

  ${media.desktop`
    width: calc(50% - 10px);
  `}

  ${props => props.large && css`
    width: calc(50% - 10px);
  `}
`

export const InputGroup = styled.div`
  position: relative;
  width: 100%;
  min-width: 185px;

  ${media.desktop`
    max-width: 100%;
  `}

  svg {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`

export const Input = styled.input`
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  padding: 6px 10px;
  color: ${colors.white};
  ${textSm}
  width: 100%;
`
